<template>
  <!-- 维修端-维修列表 -->
  <div class="index">
    <van-tabs v-model:active="type" sticky @change="onChange">
      <van-tab v-for="tab in tabList" :title="tab.title" :key="tab.type" :name="tab.type">
        <template v-if="repairList.length > 0">
          <van-list
            v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onLoad"
          >
            <div
              class="item"
              v-for="(item, index) in repairList"
              :key="index"
              @click="handleItem(item)"
            >
              <div class="item-top">
                <van-tag v-if="item.status === 1" color="#666;">{{item.status_text}}</van-tag>
                <van-tag v-if="[2, 3, 4, 41, 5, 6].includes(item.status)" type="warning">{{item.status_text}}</van-tag>
                <van-tag v-if="[7, 8].includes(item.status)">{{item.status_text}}</van-tag>
                <van-tag v-if="item.status === 9" type="success">{{item.status_text}}</van-tag>

                <div>
                  <span class="item-time">{{item.update_time}}</span>
                  <van-tag v-if="item.status === 1 && item.is_time_out" type="danger" round>已超时</van-tag>
                </div>
              </div>
              <div class="item-bottom">
                <h3>{{item.type}}</h3>
                <span class="font-24 item-subtitle">{{item.description}}</span>
                <p class="font-30 item-desc">{{item.sh_data.sh_name}}</p>
                <div class="item-btn">
                  <van-button
                    v-if="tab.type === 0 && item.is_distribution"
                    type="danger"
                    size="mini"
                    round
                    plain
                    @click.stop="handleSendOrder(item)"
                  >派单</van-button>
                  <van-button
                    v-if="item.status === 1 && item.is_show_button && !item.is_distribution"
                    type="warning"
                    size="mini"
                    round
                    plain
                    @click.stop="handleGrabOrder(item)"
                  >立即抢单</van-button>
                </div>
              </div>
            </div>
          </van-list>
        </template>
        <van-empty v-else description="空空如也！" />
      </van-tab>
    </van-tabs>
    <van-popup v-model:show="isShow" round>
      <div class="popup-content">
        <h3>可派单人员：</h3>
        <van-radio-group v-model="distribution_uid">
          <van-collapse v-model="activeName" accordion>
            <template v-for="(item, index) in workerList" :key="index">
              <van-collapse-item :title="item.depart_name" :name="item.depart_id">
                <div class="radio-list">
                  <van-radio
                    class="radio-item"
                    v-for="(child, i) in item.data"
                    :key="i"
                    :name="child.staff_id"
                    icon-size="12"
                  >{{child.staff_name}}</van-radio>
                </div>
              </van-collapse-item>
            </template>
          </van-collapse>
        </van-radio-group>
        <div class="popup-btn">
          <van-button type="primary" size="small" round block @click="onConfirm">确认</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  getRepairListApi,
  getWorkerListApi,
  postSendOrderApi,
  postRaceOrderApi,
} from '@/api'
export default {
  name: 'MaintainList',
  data() {
    return {
      activeName: '',
      tabList: [
        {title: '待确认', type: 0},
        {title: '待确认', type: 1},
        {title: '待确认', type: 2},
        {title: '待确认', type: 3},
        {title: '我的维修单', type: 4}
      ],
      isShow: false, // 是否显示派单人员
      distribution_uid: 0, // 派单人员的id
      order_id: 0, // 订单id
      user_info: {}, // 用户信息
      type: 0,
      loading: false,
      finished: false,
      repairList: [], // 维修列表数据
      count: 0, // 列表总数
      page: 1, // 页码
      workerList: [], // 可派工人员列表
    }
  },
  created() {
    if (!window.localStorage.getItem('user_info')) return
    this.user_info = JSON.parse(window.localStorage.getItem('user_info'))
    // 获取列表数据
    this.getRepairListData()
    // 获取可派工人员数据
    this.getWorkerListData()
  },
  methods: {
    getRepairListData() {
      getRepairListApi({
        access_token: this.user_info.token,
        type: this.type,
        page: this.page,
      }).then((res) => {
        console.log('维修列表数据', res)
        if (res.code !== 0) return
        if (res.data.is_leader) {
          this.tabList = [
            {title: '待确认', type: 5},
            {title: '已确认', type: 6},
            {title: '我的维修单', type: 4}
          ]
        } else {
          this.tabList = [
            {title: '未分配', type: 0},
            {title: '待处理', type: 1},
            {title: '已关闭', type: 2},
            {title: '已完成', type: 3},
            {title: '我的维修单', type: 4}
          ]
        }
        this.repairList = this.repairList.concat(res.data.list)
        this.count = res.data.count
        this.finished = this.count <= this.repairList
        this.loading = false
      })
    },
    getWorkerListData() {
      getWorkerListApi({
        access_token: this.user_info.token,
      }).then((res) => {
        console.log('可派工人员列表', res)
        if (res.code !== 0) return
        this.workerList = res.data
      })
    },
    // 加载更多
    onLoad() {
      setTimeout(() => {
        if (this.count > this.repairList.length) {
          this.page++
          this.getRepairListData()
        } else {
          this.finished = true
        }
      }, 500)
    },
    onChange() {
      this.page = 1
      this.finished = false
      this.repairList = []
      this.getRepairListData()
    },
    handleItem(item) {
      // console.log(item)
      this.$router.push({
        path: '/repair/detail',
        query: { order_id: item.id },
      })
    },
    // 派单
    handleSendOrder(item) {
      this.isShow = true
      this.order_id = item.id
    },
    // 确认派单
    onConfirm() {
      if (!this.distribution_uid) return this.$toast('请选择派单人员！')
      postSendOrderApi({
        access_token: this.user_info.token,
        distribution_uid: this.distribution_uid,
        order_id: this.order_id,
      })
        .then((res) => {
          console.log('派单', res)
          if (res.code !== 0) return
          this.$toast({
            type: 'success',
            message: '派单成功！',
            onClose: () => {
              this.onChange()
            },
          })
          this.isShow = false
        })
        .catch(() => {
          this.isShow = false
          this.$toast.fail('派单失败！')
        })
    },
    // 立即抢单
    handleGrabOrder(item) {
      postRaceOrderApi({
        access_token: this.user_info.token,
        order_id: item.id,
      }).then((res) => {
        console.log('抢单', res)
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '抢单成功',
          onClose: () => {
            this.$router.push({
              path: '/repair/detail',
              query: { order_id: item.id },
            })
          },
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.index {

  .item {
    overflow: hidden;
    margin: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    .item-top {
      font-size: 32px;
      padding: 20px;
      background-color: #1989fa;
      display: flex;
      justify-content: space-between;
      .item-time {
        font-size: 26px;
        color: #ccc;
        margin-right: 10px;
      }
    }
    .item-bottom {
      padding: 20px;
      display: flex;
      flex-direction: column;
      .item-subtitle {
        color: #666;
      }
      .item-desc {
        margin-top: 20px;
        color: #999;
      }
      .item-btn {
        display: flex;
        justify-content: flex-end;
        /deep/ .van-button {
          width: 80px;
          margin-left: 10px;
        }
      }
    }
  }
  .popup-content {
    width: 600px;
    padding: 40px 0;
    h3 {
      padding: 0 40px 20px;
    }
    .radio-list {
      display: flex;
      flex-wrap: wrap;
      /deep/ .radio-item {
        width: 30%;
        font-size: 24px;
      }
    }
    /deep/ .van-cell__title {
      font-size: 14px;
      color: #222;
      font-weight: 500;
    }
    .popup-btn {
      padding: 40px 40px 0;
    }
  }
}
</style>
